.passWrap {
  position: relative;
}

.buttonPass {
  position: absolute;
  background-color: transparent;
  color: var(--accent-main);
  top: 8px;
  right: 10px;
}

.box {
  margin: 16px auto;
  padding: 16px;
  background-color: #0f1924;
  color: #959595e5;
  -webkit-print-color-scheme: dark;
  color-scheme: dark;
  border-radius: var(--muidocs-shape-borderRadius, 12px);
  border: 1px solid;
  border-color: var(--muidocs-palette-primaryDark-700, #1f262e);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0.8125rem;
  max-width: calc(100vw - 32px);
  width: 100%;
  max-height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconCopy {
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.iconCopy:hover {
  background-color: #3b4a59;
  color: #fff;
}

.buttonApi {
  background-color: transparent;
  color: var(--accent-main);
}
