.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-default-gradient);
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 850px;
  height: 600px;
  background-color: #ffffff;
}

.title {
  font-weight: 900;
  font-size: 240px;
  background: var(--background-default-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: 500px;
  text-shadow: 0px 4px 4px rgba(193, 193, 193, 0.07);
  text-align: center;
}

.description {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 18px;
  color: var(--accent-main);
}

.text {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 18px;
  color: var(--accent-dark);
}
