.wrapper {
  width: 100%;
  height: auto;
  background: var(--background-default-gradient);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 900;
  font-size: 24px;
  margin-top: 20px;
  color: var(--text-main);

  text-shadow: 0px 4px 4px rgba(193, 193, 193, 0.07);
  text-align: center;
}
