.fullInput {
  padding: 12px 14px;
  display: inline-flex;
  margin: 8px 0;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: transparent;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  border-color: var(--board-gray);
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fullInput:focus {
  background-color: transparent;
  border-color: var(--accent-main);
  box-shadow: var(--accent-main) 0px 0px 0px 2px;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  margin: 16px 0px 10px 0px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: var(--accent-main);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 5px;
  width: 100%;
  border-radius: 12px;
  text-transform: none;
  padding: 11px 24px;
}

.form {
  width: 90%;
}

.btnGradient {
  background: linear-gradient(#111317 100%, #111317 100%) padding-box,
    linear-gradient(90deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%) border-box;
  border-radius: 8px;
  border: 1px solid transparent;
  display: inline-block;
}